export default {
  props: {
    placeholder: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    body: {
      type: String,
      required: true,
    },
  },

  computed: {
    cardPlaceholderType() {
      return `card--placeholder-type-${this.placeholder.type}`
    },

    cardPlaceholderOverlapAmount() {
      const hasPlaceholderTypeOverlap =
        this.placeholder.hasOwnProperty('type') && this.placeholder.type === 'overlap' ? true : false
      const hasOverlapAmount = this.placeholder.hasOwnProperty('overlapAmount')

      if (hasPlaceholderTypeOverlap && hasOverlapAmount) {
        const overlapAmount = this.placeholder.overlapAmount

        return `card--placeholder-overlap-amount-${overlapAmount}`
      }
    },

    cardPlaceholderAspectRatio() {
      const hasAspectRatio = this.placeholder.image.hasOwnProperty('aspectRatio')

      if (hasAspectRatio) {
        const aspectRatio = this.placeholder.image.aspectRatio

        return `card--placeholder-aspect-ratio-${aspectRatio}`
      }
    },

    placeholderAspectRatio() {
      const hasAspectRatio = this.placeholder.image.hasOwnProperty('aspectRatio')

      if (hasAspectRatio) {
        const aspectRatio = this.placeholder.image.aspectRatio

        return `aspect-ratio--${aspectRatio}`
      }
    },

    placeholderIsTransparent() {
      return this.placeholder.image.hasOwnProperty('transparent') ? this.placeholder.image.transparent : true
    },
  },
}
