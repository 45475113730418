import CardProduct from '~/components/Molecules/CardProduct/CardProduct.vue'

export default {
  data() {
    return {
      cardProduct: CardProduct,

      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 14,
        watchOverflow: true,
        centerInsufficientSlides: true,
        observer: true,
        breakpoints: {
          768: {
            spaceBetween: 28,
          },
          1024: {
            spaceBetween: 32,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },
}
